import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import islamia2 from '../assets/islamia2.jpg'
import img1 from '../assets/image1.jpg'
import techheart from '../assets/techheart.jpg'

import bgtech from '../assets/bgtech.jpg'

const Home = () => {
  const [rendered, setRendered] = useState(false)

  useEffect(() => {
    // Set rendered to true when the component mounts or refreshes
    setRendered(true)
  }, [])

  const carouselRef = useRef(null)
  const currentIndexRef = useRef(0)
  const autoSlideIntervalRef = useRef(null)

  useEffect(() => {
    if (rendered) {
      const carouselItems = carouselRef.current.querySelectorAll(
        '[data-carousel-item]'
      )

      const showItem = (index) => {
        currentIndexRef.current = index
        carouselItems.forEach((item, i) => {
          item.classList.toggle('hidden', i !== index)
        })
      }

      const handleNext = () => {
        const newIndex = (currentIndexRef.current + 1) % carouselItems.length
        showItem(newIndex)
      }

      const startSliding = () => {
        autoSlideIntervalRef.current = setInterval(() => {
          handleNext()
        }, 4000)
      }

      const handleCarouselClick = () => {
        clearInterval(autoSlideIntervalRef.current)
      }

      const currentCarouselRef = carouselRef.current
      if (currentCarouselRef) {
        currentCarouselRef.addEventListener('click', handleCarouselClick)
      }

      startSliding()

      return () => {
        if (currentCarouselRef) {
          currentCarouselRef.removeEventListener('click', handleCarouselClick)
        }

        clearInterval(autoSlideIntervalRef.current)
      }
    }
  }, [rendered])

  if (!rendered) {
    // If not yet rendered, return null or a loading message
    return <p>
      Loading...
    </p>
  }

  return (
    <div
      id='default-carousel'
      className='relative w-full h-full  bg-[#f1f2f8]'
      data-carousel='slide'
      ref={carouselRef}>
      <div className='relative overflow-hidden bg-black' style={{height:"90vh"}}>
        <div className='duration-10000 ease-in-out' data-carousel-item >
          <img
            src={img1}
            className='absolute  w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
            alt='Slide 1' />
        </div>
        <div className='duration-1 h-48 w-full ease-in-out' data-carousel-item>
          <img
            src={islamia2}
            height={800}
            width={600}
            className='absolute block w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2'
            alt='Slide 2' />
        </div>
      </div>
      <div className='absolute z-30 flex -translate-x-1/2 bottom-5 left-1/2 space-x-3 rtl:space-x-reverse'>
        <button
          type='button'
          className='w-3 h-3 rounded-full'
          aria-current='true'
          aria-label='Slide 1'
          data-carousel-slide-to='0'></button>
        <button
          type='button'
          className='w-3 h-3 rounded-full'
          aria-current='false'
          aria-label='Slide 2'
          data-carousel-slide-to='1'></button>
        <button
          type='button'
          className='w-3 h-3 rounded-full'
          aria-current='false'
          aria-label='Slide 3'
          data-carousel-slide-to='2'></button>
        <button
          type='button'
          className='w-3 h-3 rounded-full'
          aria-current='false'
          aria-label='Slide 4'
          data-carousel-slide-to='3'></button>
        <button
          type='button'
          className='w-3 h-3 rounded-full'
          aria-current='false'
          aria-label='Slide 5'
          data-carousel-slide-to='4'></button>
      </div>
      <div>
        <div className='slider mt-4 flex-col-reverse md:flex-row bg-white p-6' style={{width:"100%"}}>
          <div className='flex flex-col items-center md:flex-row justify-center md:justify-start py-12 space-y-5'>
            <div className='md:w-2/4'>
              <h2 className='mb-4 text-center text-3xl font-bold'>About Allied Skill</h2>
              <p className='text-lg text-justify mr-4 px-4 py-4 md:text-xl'>
                Welcome to Allied Skill Agency, a revolutery platform dedicated to empowering individuals and connecting them to global freelancing opportunities. Our mission is to unlock human potential, bridge skill gaps, and foster financial freedom.
              </p>
              <Link to='/AboutUs'>
                <button className=' text-white rounded-md ml-4 hover:bg-blue-600' style={{backgroundColor:"orange", padding:"10px 20px"}}>
                  Learn More!
                </button>
              </Link>
            </div>
            <img className='w-1/2 h-80 ml-7 ' src={techheart} alt='' />
          </div>
        </div>





        {/* <!-- Container for demo purpose --> */}

        <div className='bg-white container my-5 py-5 mx-auto md:px-6'>

          {/* <!-- Section: Design Block --> */}
          
          <section className='mb-32'>
            <div className='mb-16 flex flex-wrap'>
              <div className='mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6'>
                <div className='ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat' data-te-ripple-init data-te-ripple-color='light'>
                  <img src='https://www.skillindiadigital.gov.in/assets/new-ux-img/discovery-home/start-learn/learn-more-img2.svg' className='w-full' alt='Louvre'  style={{width:"500px"}}/>
                  <a href='#!'>
                    <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100'></div>
                  </a>
                </div>
              </div>
              <div className='w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6 pt-24'>
                <h3 className='mb-4 text-4xl font-bold font-size-20'>Explore, Learn, Get Skill Certified</h3>
                <div className='mb-4 flex items-center text-sm font-medium text-danger dark:text-danger-500'>
                </div>
                
                <p className='text-neutral-600 '>
                 Find Skills courses across the sectors and locations
                
                </p>
                <button type="button" class="focus:outline-none text-white  hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900 mt-4" style={{backgroundColor:"orange"}}>LEARN MORE!</button>
              </div>
            </div>
            <div className='mb-16 flex flex-wrap lg:flex-row-reverse'>
              <div className='mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pl-6'>
                <div className='ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat' data-te-ripple-init data-te-ripple-color='light'>
                  <img src='https://www.skillindiadigital.gov.in/assets/new-ux-img/discovery-home/services/discovery-service-icon1.svg' className='w-full' alt='Louvre' style={{width:"500px"}} />
                  <a href='#!'>
                    <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100'></div>
                  </a>
                </div>
              </div>
              <div className='w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pr-6 pl-20'>
                <h3 className='mb-4 text-4xl font-bold  pt-40'>Start, Change, Upgrade your Carrier</h3>
                
                <p className='text-neutral-600'>
                  Finds Jobs and Apprenticeships (in the job training)
                </p>
                <button type="button" class="focus:outline-none text-white  hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900 mt-4" style={{backgroundColor:"orange"}}>LEARN MORE!</button>
              </div>
            </div>
            <div className='flex flex-wrap'>
              <div className='mb-6 w-full shrink-0 grow-0 basis-auto lg:mb-0 lg:w-6/12 lg:pr-6'>
                <div className='ripple relative overflow-hidden rounded-lg bg-cover bg-[50%] bg-no-repeat' data-te-ripple-init data-te-ripple-color='light'>
                  <img src='https://www.skillindiadigital.gov.in/assets/new-ux-img/discovery-home/start-learn/learn-more-img1.svg' className='w-full' alt='Louvre' style={{width:"500px"}} />
                  <a href='#!'>
                    <div className='absolute top-0 right-0 bottom-0 left-0 h-full w-full overflow-hidden bg-[hsl(0,0%,98.4%,0.2)] bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100'></div>
                  </a>
                </div>
              </div>
              <div className='w-full shrink-0 grow-0 basis-auto lg:w-6/12 lg:pl-6'>
                <h3 className='mb-4 text-4xl font-bold pt-28'>Look for Skill Centre near you</h3>
          
                <p className='text-neutral-600'>
                 Join skills courses and find opportunities based on your location
                </p>
                <button type="button" class="focus:outline-none text-white  hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:focus:ring-yellow-900 mt-4" style={{backgroundColor:"orange"}}>LEARN MORE!</button>
              </div>
            </div>
          </section>
          {/* <!-- Section: Design Block --> */}
        </div>
        {/* <!-- Container for demo purpose --> */}
      </div>
      {/* </div> */}
      {/* </div> */}
    </div>
  )
}

export default Home
